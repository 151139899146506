<template>
  <div class="mt-6">
    <vs-divider>Ketentuan Komisi</vs-divider>
    <div class="vx-row">
      <div class="vx-col sm:w-12/12 w-full">
        <vs-table :data="initData.rules" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">No Urut</vs-th>
            <vs-th class="whitespace-no-wrap">Syarat Cair</vs-th>
            <vs-th class="whitespace-no-wrap">Komisi Cair</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr class="text-sm" v-for="item in data" :key="item.id">
              <vs-td class="whitespace-no-wrap">{{ item.no_urut }}</vs-td>
              <vs-td class="whitespace-no-wrap">
                <div class="flex items-center space-x-3">
                  <span>{{ item.syarat_cair }}</span>
                  <template v-if="item.syarat_cair_persen">
                    <feather-icon icon="ArrowRightIcon" svgClasses="h-4 w-4" class="mr-2"/>
                    <span>{{ item.syarat_cair_persen }}%</span>
                  </template>
                </div>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <div class="flex items-center space-x-3">
                  <span>{{ item.komisi_cair }}</span>
                  <template v-if="item.komisi_cair_persen">
                    <feather-icon icon="ArrowRightIcon" svgClasses="h-4 w-4" class="mr-2"/>
                    <span>{{ item.komisi_cair_persen }}%</span>
                  </template>
                </div>
              </vs-td>
              <vs-td class="">{{ item.description || '-' }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <vs-divider class="mt-20">Progress Cair</vs-divider>
    <p class="mb-2">Komisi senilai <span class="font-bold">Rp{{ summaryCair.grandTotalCair | idr }}</span> telah memenuhi ketentuan dari total komisi <span class="font-bold">Rp{{ initData.header.total_komisi | idr }}</span>.</p>
    <vs-progress color="success" :height="8" :percent="summaryCair.grandTotalCair / initData.header.total_komisi * 100"/>
    <p class="mt-2 text-sm">({{ summaryCair.grandTotalCair / initData.header.total_komisi * 100 | idr }}% / 100.00%)</p>

    <vs-divider class="mt-20">Claim Pencairan</vs-divider>
    <div class="mb-3">
      <vs-button v-if="!isAllClaimed" class="px-3 py-2" color="primary" :disabled="checkedItems.length < 1" @click="showModalClaim">
        Claim {{ checkedItems.length }} Item
      </vs-button>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <vs-table :data="items" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap text-center">#</vs-th>
            <vs-th class="whitespace-no-wrap text-left w-10" v-if="!isAllClaimed">
              <vs-checkbox size="small" class="text-xs" v-model="checkAll" @change="toggleCheckAll($event.target.checked)">Claim</vs-checkbox>
            </vs-th>
            <vs-th class="whitespace-no-wrap text-center">Status</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Claim ID</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Total Cair</vs-th>
            <vs-th class="whitespace-no-wrap text-center">PPh</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Keterangan</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Created At</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr class="text-sm" v-for="(item, index) in data" :key="item.uuid">
              <vs-td>{{ index + 1 }}</vs-td>
              <vs-td class="whitespace-no-wrap text-left" v-if="!isAllClaimed">
                <vs-checkbox v-if="!item.is_claimed" size="small" class="text-xs" v-model="item.checked">Claim</vs-checkbox>
              </vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.is_claimed ? 'Sudah Claim' : 'Belum Claim' }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.is_claimed ? ('#ID' + item.id_komisi_payment) : '-' }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.total | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.pph_jml | idr }}</vs-td>
              <vs-td class="">{{ item.description || '-'}}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.created_at }}</vs-td>
            </vs-tr>
            <vs-tr class="text-sm font-bold">
              <vs-td></vs-td>
              <vs-td v-if="!isAllClaimed"></vs-td>
              <vs-td></vs-td>
              <vs-td></vs-td>
              <vs-td class="text-right">{{ summaryCair.grandTotalCair | idr }}</vs-td>
              <vs-td class="text-right">{{ summaryCair.grandTotalPph | idr }}</vs-td>
              <vs-td></vs-td>
              <vs-td></vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <!--modals-->
    <Claim :isActive.sync="modalClaim.active"
           :listCairItems="checkedItems"
           @success="emitClaimed"/>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'TabKetentuan',
  components: {
    Claim: () => import('@/views/pages/marketing/komisi/tabs/ketentuan/Claim')
  },
  props: {
    isActive: { type: Boolean },
    initData: { type: Object }
  },
  watch: {
    'initData.cairHistory': {
      immediate: true,
      handler (items) {
        this.items = _.map(_.cloneDeep(items), item => {
          item.checked = false
          return item
        })
      }
    }
  },
  computed: {
    isAllClaimed () {
      return _.find(this.items, item => !item.is_claimed) === undefined
    },
    checkedItems () {
      return _.filter(this.items, item => item.checked)
    },
    summaryCair () {
      return {
        grandTotalCair: _.sumBy(this.initData.cairHistory, item => parseFloat(item.total)),
        grandTotalDpp: _.sumBy(this.initData.cairHistory, item => parseFloat(item.dpp)),
        grandTotalPph: _.sumBy(this.initData.cairHistory, item => parseFloat(item.pph_jml)),
        grandTotalHargaDasarKomisi: _.sumBy(this.initData.cairHistory, item => parseFloat(item.harga_dasar_komisi))
      }
    }
  },
  data () {
    return {
      checkAll: false,
      items: [],
      modalClaim: {
        active: false
      }
    }
  },
  methods: {
    showModalClaim () {
      this.modalClaim.active = true
    },

    toggleCheckAll (isChecked) {
      this.items = _.map(this.items, item => {
        if (!item.is_claimed) {
          item.checked = isChecked
        }
        return item
      })
    },

    emitClaimed () {
      this.$emit('claimed')
    }
  }
}
</script>
